<div class="row card-header">
    <div class="col s12 card-header-container blue-grey darken-2">
        <p class="card-header-text">{{'titles.your-booking-details' | translate | uppercase}}</p>
    </div>
</div>
<mat-card class="card" *ngIf="form">
    <mat-card-content [formGroup]="form">
        <div class="row form-section">
            <div formArrayName="travelers" *ngFor="let travelerFormGroup of travelersFormGroups; let i = index;">
                <div [formGroupName]="i">
                    <div class="col s12 m6 detail-data">
                        <div>
                            <mat-form-field>
                                <input tabindex="1" type="text" maxlength="140" matInput
                                    placeholder="{{ 'placeholders.first-name' | translate }}"
                                    [formControl]="util.getFormControl(travelerFormGroup, 'name')">
                                <div>
                                    <small
                                        *ngIf="util.getFormControl(travelerFormGroup, 'name').hasError('required')
                                                                                          && util.getFormControl(travelerFormGroup, 'name').touched"
                                        class="form-message text-danger">{{'labels.required' | translate}}</small>
                                </div>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <input tabindex="2" type="text" maxlength="140" matInput
                                    placeholder="{{ 'placeholders.last-name' | translate }}"
                                    [formControl]="util.getFormControl(travelerFormGroup, 'lastName')">
                                <div>
                                    <small
                                        *ngIf="util.getFormControl(travelerFormGroup, 'lastName').hasError('required')
                                                                                                      && util.getFormControl(travelerFormGroup, 'lastName').touched"
                                        class="form-message text-danger">{{'labels.required' | translate}}</small>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col s12 m6 detail-data">
                        <div>
                            <mat-form-field class="">
                                <input type="text" tabindex="3" maxlength="140" matInput pattern="^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
                                    placeholder="{{ 'placeholders.email' | translate }}"
                                    [formControl]="util.getFormControl(travelerFormGroup, 'email')">
                                <mat-hint align="start">
                                    <strong>{{'texts.confirmation-is-sent-to-this-address-note' | translate}}</strong>
                                </mat-hint>
                                <div>
                                    <small
                                        *ngIf="util.getFormControl(travelerFormGroup, 'email').hasError('required')
                                                                                              && util.getFormControl(travelerFormGroup, 'email').touched"
                                        class="form-message text-danger">{{'labels.required' | translate}}</small>
                                </div>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <input tabindex="4" type="text" maxlength="140" matInput
                                    placeholder="{{ 'placeholders.phone-number' | translate }}"
                                    [formControl]="util.getFormControl(travelerFormGroup, 'phoneNumber')">
                                <div>
                                    <small
                                        *ngIf="util.getFormControl(travelerFormGroup, 'phoneNumber').hasError('required')
                                                                                                          && util.getFormControl(travelerFormGroup, 'phoneNumber').touched"
                                        class="form-message text-danger">{{'labels.required' | translate}}</small>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <mat-form-field>
                        <input type="textarea" tabindex="5" maxlength="140" matInput
                            placeholder="{{ 'placeholders.special-request' | translate }}"
                            formControlName="specialRequest">
                        <mat-hint align="start"><strong>{{'texts.special-request-not-guarateed' | translate}}</strong>
                        </mat-hint>
                        <div>
                            <small
                                *ngIf="form.get('specialRequest').hasError('required')
                                                                                  && form.get('specialRequest').touched"
                                class="form-message text-danger">{{'labels.required' | translate}}</small>
                        </div>
                    </mat-form-field>
                    <div class="require-airport-transportation">
                        <mat-checkbox formControlName="requireAirportTransportation"></mat-checkbox>
                            <label class="checkbox-label" (click)="clickAirportTransportation()">{{ 'labels.require-airport-transportation' | translate }}</label>
                        <p class="foot-note">
                            {{'texts.require-airport-transportation-note' | translate}}</p>
                    </div>
                    <div>
                        <mat-checkbox formControlName="termsConditionsAccepted" (click)="openTermsAndConditions()">
                        </mat-checkbox>
                        <label class="checkbox-label" (click)="openTermsAndConditions()">{{ 'labels.i-accepted-that-i' | translate }}</label><label
                            (click)="openTermsAndConditions()"
                            class="terms-and-conditions">{{ 'labels.terms-and-conditions' | translate }}</label>
                        <div>
                            <small
                                *ngIf="form.get('termsConditionsAccepted').hasError('required')
                                                                                      && form.get('termsConditionsAccepted').touched"
                                class="form-message text-danger">{{'labels.required' | translate}}</small>
                        </div>
                        <p class="foot-note">{{'texts.terms-and-conditions-note' | translate}}</p>

                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>