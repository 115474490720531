import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingContract } from '../../services';
import { Invoice } from '../../models';
import { Gtag } from 'angular-gtag';


@Component({
  selector: 'namu-confirmation-view',
  templateUrl: './confirmation-view.component.html',
  styleUrls: ['./confirmation-view.component.scss']
})

export class ConfirmationViewComponent implements OnInit {
  public searchData$ = this.activatedRoute.queryParams;
  public invoice: Invoice;
  
  crvRoute = false;

  constructor(private bookingService: BookingContract, private activatedRoute: ActivatedRoute) { 
    
    this.crvRoute = !!ActivatedRoute;
  }

  ngOnInit() {
    this.invoice = this.bookingService.getInvoiceObject();
  }
}