import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Package } from '../../../models';
import * as _ from "lodash";

@Component({
    selector: 'namu-booking-summary',
    templateUrl: './booking-summary.component.html',
    styleUrls: ['./booking-summary.component.scss']
})



export class BookingSummaryComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute) { }

    package: Package;

    @Input() set packages(packages: Package[]){
        if (packages) {
            this.package = packages[0];
        }
    };
    
    ngOnInit() {}
}