import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { PackageContract } from './package-service.contract';
import { Package } from '../models/package';
import { AppSettings, DATE_FORMAT } from '../app.settings';
import { HttpClientService } from './http-client.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import moment from 'moment-es6';
import { SpecialRequest } from '../models';

@Injectable()
export class PackageService extends PackageContract {

  constructor(private httpService: HttpClientService, private appSettings: AppSettings) {
    super();
  }

  private endpoint: string = `${this.appSettings.apiUrl}/Packages`;

  searchPackages(start: moment.Moment, end: moment.Moment, rooms: number, adultsCount: number, orderByTemplateId: number = 0): Observable<Package[]> {
    return this.httpService.get(
      `${this.endpoint}/Search/${start.format(DATE_FORMAT.server)}/${end.format(DATE_FORMAT.server)}/Rooms/${rooms}/Adults/${adultsCount}/Template/${orderByTemplateId}/OrderByTemplate/${(orderByTemplateId != 0)}`
    );
  }

  getPackageByTemplateId(start: moment.Moment, end: moment.Moment, rooms: number, adultsCount: number, templateId: number, orderByTemplateId: number, headers?: HttpHeaders): Observable<Package[]> {
    return this.httpService.get(
      `${this.endpoint}/Search/${start.format(DATE_FORMAT.server)}/${end.format(DATE_FORMAT.server)}/Rooms/${rooms}/Adults/${adultsCount}/Template/${templateId}/OrderByTemplate/${(orderByTemplateId != 0)}`
    );
  }

  sendReservations(invoiceId: number): Observable<boolean> {
    return this.httpService.post(`${this.endpoint}/Reservations/${invoiceId}`);
  }
}
