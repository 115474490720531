import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TermsAndConditionsDialogComponent } from '../../dialogs/terms-and-conditions-dialog.component';
import { ComponentUtilities } from '../../../utilities';

@Component({
  selector: 'namu-customer-booking-details',
  templateUrl: './customer-booking-details.component.html',
  styleUrls: ['./customer-booking-details.component.scss']
})

export class CustomerBookingDetailsComponent implements OnInit {
  constructor(public dialog: MatDialog, private fb: FormBuilder) { }
  form: FormGroup = null;
  util = new ComponentUtilities();

  @Input() set customerDetailsForm(form: FormGroup) {
    this.form = form;
  }


  openTermsAndConditions() {
    const dialogRef = this.dialog.open(TermsAndConditionsDialogComponent, {
      width: "90%"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (undefined !== result) {
        if (result.action) {
          this.form.patchValue({
            termsConditionsAccepted: true
          });
        } else {
          this.form.patchValue({
            termsConditionsAccepted: false
          });
        }
      }
    });
  }

  clickAirportTransportation() {
    var formValue = this.form.value;
    this.form.patchValue({
      requireAirportTransportation: !formValue.requireAirportTransportation
    });
  }


  loadingSpinner: {
    savingCustomerDetails: boolean;
  }

  ngOnInit() {

  }

  get travelersFormArray(): FormArray {
    return this.form.controls["travelers"] as FormArray;
  }

  get travelersFormGroups(): FormGroup[] {
    return this.travelersFormArray.controls as FormGroup[];
  }
}