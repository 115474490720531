import { RoomType } from './room';
import { Amenity } from '.';
import { RatePlan } from './rate-plan';
import moment from 'moment-es6';

export class Product {
    constructor() { }
    public id: number;
    public startDate: Date;
    public endDate: Date;
    public checkInTime: moment.Moment;
    public checkOutTime: moment.Moment;
    public roomsPrice: number;
    public nightCount: number;
    public locationName: string;
    public hotelName: string;
    public imagePath: string;
    public roomTypes: RoomType[];
    public amenities: Amenity[];
    public ratePlans: RatePlan[]
  }