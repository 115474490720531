<namu-search-bar action="search">
    <br>
    <mat-card>
        <div class="row">
            <div class="col m12">
                <h4>Our online vacation booking tool allows them – and you – to do exactly that.</h4>
                <p>Just choose your dates (a minimum of four nights), add how many people you are and how many rooms you
                    want, and listo! A selection of packages ready to go for you will appear. Look them over, book your
                    package and you’re good to go.</p>
                <p>It’s the quickest, easiest way to book a vacation to Costa Rica without losing that personal touch
                    you get with dealing with a local agency here on the ground. We’re still here for you if you need us
                    before you arrive or in-country. Our client service team here in Costa Rica is the best in the
                    business!</p>
                <p><b>Over the years we’ve built up a great reputation as an online travel agency in Costa Rica. </b>
                </p>
                <p>As a result, we have excellent relations with our hotel and tour company partners all over the country.</p>
                <p>This allows us to pass on the deals they offer us to you. When you book your online package with us you can rest assured that you’re receiving added value, in price and/or amenities (special rates, upgrades etc.).</p>
                <p>So what are you waiting for? It’s now easier than ever to arrange your vacation in Costa Rica! Click below and we’ll see you here soon!</p>
                <p>If you have any questions contact us at <b>support@centralamerica.com</b> or call us toll-free on <b>1-800-608-9887</b>.</p>
            </div>
        </div>

        <div class="row">
            <div class="col m12">
                <h4>Highlights of our online booking tool</h4>
                <ol>
                    <li>All packages are pre-built and include hotel and tours.</li>
                    <li>Our handpicked hotels and tour operator offer 5,4 and 3 star services.</li>
                    <li>Your online purchase is guaranteed. What you see is what you get. Our dedicated reservations
                        team in San Jose, Costa Rica will reconfirm everything to ensure your vacation goes smoothly.
                    </li>
                    <li>We are local experts with almost 20 years in the Costa Rican travel industry. As such, we know
                        what travelers prefer and our pre-built online itineraries reflect that.</li>
                    <li>For now, our online booking tool can only perform a search for adults-only packages (this does
                        not mean the hotel will be an adult-only property).</li>
                    <li>No other Central American destination apart from Costa Rica is offered at this time.</li>
                    <li>All packages have a 4-night minimum stay.</li>
                    <li>When you book through us you’ll always receive an added value in price and/or amenities.</li>
                    <li>We offer a dedicated support team during Costa Rica office hours for any issues or questions you
                        might have while in-country.</li>
                    <li>We offer flexible cancellation policies.</li>
                </ol>
            </div>
        </div>
    </mat-card>
</namu-search-bar>