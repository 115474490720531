import { StripeService } from './../../../services/stripe.service';
import { Component, OnInit, Input, AfterViewInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { PackageContract, InvoiceContract, BookingContract } from '../../../services';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import moment from 'moment-es6';
import { DATE_FORMAT } from './../../../app.settings';
import { mergeMap, take, switchMap, switchMapTo, map, tap, ignoreElements } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { Package, Invoice, SearchData } from '../../../models';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Moment } from 'moment';
import { utils } from 'protractor';
import { ComponentUtilities } from '../../../utilities';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'namu-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})

export class BookingDetailsComponent implements OnInit, AfterViewInit {
  constructor(private activatedRoute: ActivatedRoute,
    private packageService: PackageContract,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private invoiceService: InvoiceContract,
    private bookingService: BookingContract,
    private dialog: MatDialog,
    private router: Router) {
  }

  packages$: Observable<Package[]>;
  package: Package = null;
  customerDetailsForm: FormGroup = null;
  packageSubscription: Subscription;
  searchCriteriaSubscription: Subscription;
  invoiceSubscription: Subscription;
  private invoice: Invoice;
  util = new ComponentUtilities();
  private searchCriteria: SearchData;
  showThereIsAvailabilityMessage = false;
  private _isSearchVisible = false;

  private routeSubscription: Subscription;

  private searchData$ = this.activatedRoute.queryParams;
  @ViewChild('chargingCardMsg') chargingCardMsg: TemplateRef<any>;
  @ViewChild('sendingReservationsMsg') sendingReservationsMsg: TemplateRef<any>;
  @ViewChild('success') success: TemplateRef<any>;
  @ViewChild('error') error: TemplateRef<any>;

  fullLoad = false;

  ngOnInit() {
    this.packages$ = this.searchData$.pipe(
      mergeMap(({ start, end, adults, rooms, templateId, source }) => {
        var currentDate = moment();
        start = moment(start, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);
        end = moment(end, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);

        start = start.format(DATE_FORMAT.server);
        end = end.format(DATE_FORMAT.server);
        
        if ((!start && !end) || moment(start, DATE_FORMAT.server) < currentDate) {
          var searchDefaultData = this.util.getDefaultSearchCriteria();
          start = searchDefaultData.start;
          end = searchDefaultData.end;
          adults = searchDefaultData.adults;
          rooms = searchDefaultData.rooms;
          
          this.searchCriteria = searchDefaultData;
        }
        this.showThereIsAvailabilityMessage = !!source;
        return of({ start, end, adults, rooms, templateId });
      }),
      mergeMap(({ start, end, adults, rooms, templateId }) => {
        this.searchCriteria = new SearchData(start, end, adults, rooms, templateId);
        return this.packageService.getPackageByTemplateId(moment(start, DATE_FORMAT.server), moment(end, DATE_FORMAT.server), +rooms, +adults, templateId, 0)
      }
      ));

    this.packageSubscription = this.packages$.subscribe(packages => {
      this.package = packages[0];

      var isInvalidTemplate = !this.package;
      var isPackageSoldOut = isInvalidTemplate ? false : this.package.isSoldOut;

      var queryParams: any = {
        start: this.searchCriteria.start.format(DATE_FORMAT.server),
        end: this.searchCriteria.end.format(DATE_FORMAT.server),
        adults: this.searchCriteria.adults,
        rooms: this.searchCriteria.rooms,
        templateId: this.searchCriteria.templateId
      };

      if(isInvalidTemplate) queryParams.invalidTemplate = isInvalidTemplate;
      if(isPackageSoldOut) queryParams.isSoldOut = isPackageSoldOut;

      if (isPackageSoldOut || isInvalidTemplate) {
        this.router.navigate(['search'], {queryParams})
      }
    });

    this.setupForm();
  }

  openSearch(){
    console.log('open search');
  }

  ngAfterViewInit() {
    this.stripeService.init();
    this.fullLoad = true;
  }

  payViaStripe(email: string, amount: number) {
    amount = Math.round(amount);

    let chargingCardLoader, sendingReservationsLoader;
    this.stripeService.openPayPopup(email, amount)
      .pipe(
        tap(e => chargingCardLoader = this.dialog.open(this.chargingCardMsg, { disableClose: true })),
        switchMap(token => this.invoiceService.applyCharge(this.invoice.id, token.id, amount * 100, "")),
        mergeMap(e => {
          if (e.paid) {
            chargingCardLoader.close();
            return of(e);
          } else {
            this.dialog.open(this.error);
            chargingCardLoader.close();
            return ignoreElements();
          }
        }),
        switchMapTo(this.searchData$),
        tap(e => sendingReservationsLoader = this.dialog.open(this.sendingReservationsMsg, { disableClose: true })),
        switchMap(() => this.packageService.sendReservations(this.invoice.id)),
        tap(reservationsSended => {
          if (reservationsSended) {
            this.bookingService.setInvoiceObject(this.invoice);
            this.routeSubscription = this.activatedRoute.queryParams.subscribe((params: Params) => {
              this.router.navigate(['confirmation'])
            });
          }
          sendingReservationsLoader.close()
        })
      )
      .subscribe(success => {
        if (success) {
          tap(e => this.dialog.open(this.success));
        }
        else {
          this.dialog.open(this.error);
        }
      });
  }


  public saveChanges() {
    if (this.customerDetailsForm.valid) {
      (<any>window).dataLayer.push({
        'event': 'FormSubmittedSuccessfully',
        'formType': 'Booking Details Data Layer',
        'formPosition': 'Footer'
      });


      let customerDetails = this.customerDetailsForm.value;
      this.payViaStripe(customerDetails.travelers[0].email, this.package.totalRackPrice);

      if (!customerDetails.id) {
        this.searchCriteriaSubscription = this.searchData$.subscribe(({ start, end, adults }) => {
          this.invoice = this.createInvoice(customerDetails, start, end, adults);

          this.invoiceSubscription = this.invoiceService.createInvoice(this.invoice).subscribe(res => {
            this.invoice.id = res.id;
          });
        });

      } else {
        //Update
      }
    }

    this.validateAllFields(this.customerDetailsForm);
  }

  private createInvoice(customerDetails: any, start: Moment, end: Moment, adults: number) {
    let invoice: Invoice = null;
    invoice = new Invoice(this.package.id,
      moment(start, DATE_FORMAT.server), moment(end, DATE_FORMAT.server),
      adults, 0, customerDetails.specialRequest, customerDetails.requireAirportTransportation, customerDetails.termsConditionsAccepted,
      this.package.totalRackPrice, this.package.products, this.package.tours, customerDetails.travelers);

    return invoice;
  };

  private setupForm() {
    this.customerDetailsForm = this.fb.group({
      travelers: this.fb.array([
        this.fb.group({
          name: [null, Validators.required],
          lastName: [null, [Validators.required]],
          email: [null, [Validators.required]],
          phoneNumber: [null]
        })
      ]),
      specialRequest: [null],
      requireAirportTransportation: [false],
      termsConditionsAccepted: [false, [Validators.requiredTrue]]
    });
  }

  ngOnDestroy(): void {
    if (this.packageSubscription) this.packageSubscription.unsubscribe();
    if (this.searchCriteriaSubscription) this.searchCriteriaSubscription.unsubscribe();
    if (this.invoiceSubscription) this.invoiceSubscription.unsubscribe();
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormArray) {
        for (const control1 of control.controls) {
          if (control1 instanceof FormControl) {
            control1.markAsTouched({
              onlySelf: true
            });
          }
          if (control1 instanceof FormGroup) {
            this.validateAllFields(control1);
          }
        }
        // control.markAsTouched();
      }
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });

    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  get travelersFormArray(): FormArray {
    return this.customerDetailsForm.controls["travelers"] as FormArray;
  }

  get travelersFormGroups(): FormGroup[] {
    return this.travelersFormArray.controls as FormGroup[];
  }

  get isSearchVisible(): boolean {
    return this._isSearchVisible;
  }

  set isSearchVisible(value){
    console.log(value);
    this._isSearchVisible = value;
  }
}

