import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppComponent } from './app.component';
import { RoutingModule } from './routes';
import { MaterialComponentsModule } from './material-components.module';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatCardModule, MatTooltipModule, MatDividerModule, MatDialogModule } from '@angular/material';
import {
  SearchBoxComponent,
  MultiLanguageComponent,
  LandingPageComponent,
  SearchViewComponent,
  HotelComponent,
  HotelListComponent,
  PackageComponent,
  PackageListComponent,
  TourComponent,
  TourListComponent,
  BookingDetailsComponent,
  BookingSummaryComponent,
  CustomerBookingDetailsComponent,
  PersonalInformationComponent,
  StepperComponent,
  TermsAndConditionsDialogComponent,
  SearchBarComponent,
  SuccessAnimationComponent,
  ErrorAnimationComponent,
  ConfirmationViewComponent,
  RoomDialogComponent,
  TourDialogComponent,
  RateplanDialogComponent,
  FAQComponent,
  AmenitiesDialogComponent,
  IntroductionComponent,
  FooterComponent,
  BookingSummaryV2Component
} from './components';
import { DATE_FORMAT, AppSettings, appSettingsValues } from './app.settings';

import { NamuCoreModule } from 'namu-core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PackageContract, PackageService, StripeService, InvoiceContract, InvoiceService, BookingService, BookingContract } from './services';
import { HttpClientService } from './services/http-client.service';
import { StripeCheckoutModule } from 'ng-stripe-checkout';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const MY_DATE_FORMATS = {
  parse: {
    dateInput: DATE_FORMAT.server
  },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "short" },
    dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "long" }
  }
};

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  constructor() {
    super(localStorage.getItem("namu-web-language"));
  }
  format(date: Moment, displayFormat: string): string {
    if (displayFormat === "input") {
      return date
        .locale(localStorage.getItem("namu-web-language"))
        .format("MMM DD, YYYY");
    } else {
      return date
        .locale(localStorage.getItem("namu-web-language"))
        .format("MMM DD, YYYY");
    }
  }
}
@NgModule({
  declarations: [
    TermsAndConditionsDialogComponent,
    AppComponent,
    MultiLanguageComponent,
    SearchBoxComponent,
    LandingPageComponent,
    SearchViewComponent,
    HotelComponent,
    HotelListComponent,
    PackageComponent,
    PackageListComponent,
    TourComponent,
    TourListComponent,
    HotelListComponent,
    HotelComponent,
    BookingDetailsComponent,
    BookingSummaryComponent,
    CustomerBookingDetailsComponent,
    PersonalInformationComponent,
    StepperComponent,
    SearchBarComponent,
    SuccessAnimationComponent,
    ErrorAnimationComponent,
    ConfirmationViewComponent,
    RoomDialogComponent,
    TourDialogComponent,
    RateplanDialogComponent,
    FAQComponent,
    AmenitiesDialogComponent,
    IntroductionComponent,
    FooterComponent,
    BookingSummaryV2Component
  ],
  entryComponents: [
    TermsAndConditionsDialogComponent,
    RoomDialogComponent,
    TourDialogComponent,
    RateplanDialogComponent,
    AmenitiesDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    RoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MaterialComponentsModule,
    NamuCoreModule,
    MatCardModule,
    MatTooltipModule,
    MatDividerModule,
    AngularFontAwesomeModule,
    MatDialogModule,
    StripeCheckoutModule
  ],
  providers: [
    {
      provide: PackageContract,
      useClass: PackageService
    },
    {
      provide: InvoiceContract,
      useClass: InvoiceService
    },
    {
      provide: AppSettings,
      useValue: appSettingsValues
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: BookingContract,
      useClass: BookingService
    },
    StripeService,
    HttpClientService,
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },],
  bootstrap: [AppComponent],

})
export class AppModule { }
