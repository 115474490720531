<div class="row hotel-summary">
    <div class="col s12">
        <span class="hotel-name show-on-large hide-on-med-and-down">{{product.hotelName}}</span>
        <span class="hotel-name show-on-medium-and-down hide-on-large-only">{{product.hotelName}} - {{product.roomTypes[0].roomTypeName}}</span>
    </div>
</div>
<div class="row narrow-line">
    <div class="col s12">
        <span class="left">
            <fa class="icon-location" name="map-marker" size="sm"></fa> {{ product.locationName }}
        </span>
    </div>
</div>
<div class="row narrow-line">
    <div class="col s12 m7">
        <img [src]="product.imagePath" class="hotel-image" alt="">
    </div>
    <div class="col s12 m5 stay-info-container">
        <p class="narrow-line">
            <span class="stay-info included-description">{{ packageDescription }}</span>
            <span class="stay-info">
                <fa class="stay-info-icon" name="calendar" size="sm"
                    [matTooltip]="'labels.checkin-checkout' | translate" matTooltipPosition="above"></fa>
                {{product.startDate | date: 'MMM dd, yyyy'}}
                {{ 'labels.to' | translate }}
                {{product.endDate | date: 'MMM dd, yyyy'}}
            </span>
            <span *ngFor="let ratePlan of product.ratePlans" class="stay-info">
                <fa class="stay-info-icon" name="coffee" size="sm" [matTooltip]="'labels.rateplan' | translate"
                    matTooltipPosition="above"></fa><button mat-button class="room-type"
                    [matTooltip]="'texts.see-rateplan-details' | translate" matTooltipPosition="after"
                    (click)="openRatePlanDetails(ratePlan)">{{ratePlan.ratePlanName}}<mat-icon class="room-type-icon">
                        open_in_new
                    </mat-icon></button>
            </span>
        </p>
        <p *ngFor="let room of product.roomTypes" class="narrow-line">
            <span class="distribution">
                <fa class="room-type-icon" name="male" size="sm" *ngFor="let person of getArray(room.adultsCount)"
                    [matTooltip]="'labels.people-per-room' | translate" matTooltipPosition="above">
                </fa>
            </span>
            <button mat-button class="room-type" [matTooltip]="'texts.see-room-details' | translate"
                matTooltipPosition="after" (click)="openRoomDetails(room)">{{room.numberOfRooms}} x
                {{room.roomTypeName}}<mat-icon class="room-type-icon">open_in_new</mat-icon></button>
        </p>
        <p *ngIf="pax > 0">
            <span class="tour-info tour-included">
                <fa class="tour-info-icon" name="bus" size="sm"></fa>Tours included for {{ pax }} people
            </span>
        </p>
    </div>
</div>
<div class="row amenities-container">
    <div class="col s12 amenities-col">
        <div class="amenity-title" *ngIf="product.amenities.length > 0">{{ 'labels.hotel-features' | translate }}:</div>
        <span class="amenities" *ngFor="let amenity of product.amenities" (click)="openAmenities(product.amenities)">
            <p class="ammenity-icon">
                <mat-icon class="icon" [matTooltip]="amenity.name" matTooltipPosition="above">{{amenity.icon}}
                </mat-icon>
            </p>
            <span class="amenity-text">{{amenity.name}}</span>
        </span>
    </div>
</div>
<div class="row book-now" *ngIf="!isSoldOut">
    <div class="col s12 book-now-col">
        <button mat-raised-button class="card-btn blue darken-3"
            (click)="redirectToBooking(packageId)">{{ 'labels.book-now' | translate }}</button>
    </div>
</div>