import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Product, Amenity } from '../../models';
import { RoomType } from '../../models';
import { RatePlan } from '../../models';
import { MatDialog } from '@angular/material';
import { RoomDialogComponent } from '../room-dialog/room-dialog.component';
import { RateplanDialogComponent } from '../rateplan-dialog/rateplan-dialog.component';
import { Subscription } from 'rxjs';
import { ComponentUtilities } from '../../utilities/component-utilities';
import { DATE_FORMAT } from '../../app.settings';
import { AmenitiesDialogComponent } from '../dialogs/amenities-dialog/amenities-dialog.component';

@Component({
    selector: 'namu-hotel',
    templateUrl: './hotel.component.html',
    styleUrls: ['./hotel.component.scss']
})

export class HotelComponent implements OnInit {
    private routeSubscription: Subscription;
    private util = new ComponentUtilities();

    isTinyMobile: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog, private router: Router) { }

    @Input() product: Product;
    @Input() packageDescription: string;
    @Input() packageId: number;
    @Input() isSoldOut: boolean;
    @Input() pax: number;

    ngOnInit() {
        this.setTinyMobile();
    }

    public getArray(length: number) {
        return new Array(length);
    }

    openRoomDetails(room: RoomType) {
        console.log("room:" + room.htmlDescription)
        const dialogRef = this.dialog.open(RoomDialogComponent, {
            width: "70%",
            data: room
        });
    }

    openRatePlanDetails(ratePlan: RatePlan) {
        const dialogRef = this.dialog.open(RateplanDialogComponent, {
            width: "70%",
            data: ratePlan
        });
    }

    openAmenities(amenities: Amenity[]) {
        if (this.isTinyMobile) {
            const dialogRef = this.dialog.open(AmenitiesDialogComponent, {
                width: "70%",
                data: amenities
            });
        }
    };

    redirectToBooking(templateId: number) {
        var useDefaultCriteria = false;
        var defaultCriteria = this.util.getDefaultSearchCriteria();

        this.routeSubscription = this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (!params.end && !params.start) useDefaultCriteria = true;
            this.router.navigate(['booking'], {
                queryParams: {
                    start: useDefaultCriteria ? defaultCriteria.start.format(DATE_FORMAT.server) : params.start,
                    end: useDefaultCriteria ? defaultCriteria.end.format(DATE_FORMAT.server) : params.end,
                    adults: useDefaultCriteria ? defaultCriteria.adults : params.adults,
                    rooms: useDefaultCriteria ? defaultCriteria.rooms : params.rooms,
                    templateId: templateId,
                    source: params.source
                }
            })

        });
    }

    setTinyMobile() {
        if (window.innerWidth <= 386) {
            this.isTinyMobile = true;
        } else {
            this.isTinyMobile = false;
        }
    }

    @HostListener('window:resize') onResize(event) {
        this.setTinyMobile();
    }
}