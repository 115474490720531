import { FormGroup, FormControl, FormArray } from "@angular/forms";
import * as moment from "moment";
import { SearchData } from '../models';
export class ComponentUtilities {
  getFormControl(formGroup: FormGroup, controlName: string): FormControl {
    return formGroup.get(controlName) as FormControl;
  }

  getFormGroup(formGroup: FormGroup, groupName: string): FormGroup {
    return formGroup.get(groupName) as FormGroup;
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  generateRenderId() {
    return (Math.random() * 1e32).toString(36);
  }

  /**
   * Generate a code from a value
   *
   * Takes the (first 3 letters) + (Last letter) + (random number (0-9))
   * @param  {string} val
   */
  getCodeAutoGenerated(val: string) {
    let randomNumber = this.randomIntFromInterval(0, 9);
    const shortStartCode = val
      .replace(/ /g, "")
      .substring(0, 3)
      .toUpperCase();
    const shortEndCode = val
      .replace(/ /g, "")
      .substring(val.length - 2, val.length)
      .toUpperCase();

    return shortStartCode + shortEndCode + randomNumber;
  }

  /**
   *
   * @param contactType
   * @param hotel
   */
  retreivePhone(contactType: any, hotel: any) {
    const contact = hotel.productContactList.find(
      f => f.contactTypeId === contactType
    );
    if (contact) {
      return contact.phoneNumber;
    }
    return " ";
  }

  retrieveContactInfo(contactType: any, hotel: any) {
    const contact = hotel.productContactList.find(
      f => f.contactTypeId === contactType
    );
    if (contact) {
      if(contact.phoneNumber)
           return contact.phoneNumber;
      else
        return contact.email;
    }
    return " ";
  }

  /**
   *This will mark all controls for a given formGroup as touch and if they are required they will highligh in red
   *
   * @param {FormGroup} formGroup
   * @memberof ComponentUtilities
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  validateStartEdge(formGroup: FormGroup) {
    if (!formGroup) return null;

    let start = formGroup.value as moment.Moment;

    if (
      null != start &&
      start.startOf("day").isBefore(moment().startOf("day"))
    ) {
      return {
        invalidEdge: true
      };
    }
    return null;
  }

  /**
   *Checks every FG within a FormArray
   *
   * @param {FormArray} formArray
   * @returns {boolean}
   * @memberof ComponentUtilities
   */
  checkFormGroupValidStatus(formArray: FormArray): boolean {
    let isValid = true;

    formArray.controls.forEach(control => {
      if (!control.valid && !control.pristine) isValid = false;
    });

    return isValid;
  }

  assignFormArray(formArray: FormArray, formGroup: FormGroup[]) {
    while (formArray.length > 0) formArray.removeAt(0);

    formGroup.forEach(fc => {
      formArray.push(fc);
    });
  }

  getDefaultSearchCriteria(): SearchData {
    var searchData = new SearchData();
    
    searchData.start = moment().add(1, 'days');
    searchData.end = moment().add(5, 'days');
    searchData.adults = 2;
    searchData.rooms = 1;

    return searchData;
  }
}
