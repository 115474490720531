import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatExpansionPanel } from '@angular/material';

@Component({
  selector: 'namu-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})


export class FAQComponent implements OnInit, AfterViewInit {
  constructor(private activatedRoute: ActivatedRoute) { }

  readonly panels = {
    termsAndContitionsPanel: "TermsAndContitionsPanel",
    travelFaq: "TravelFaqPanel",
    getInTouch: "GetInTouchPanel"
  }

  @ViewChild('TermsAndContitionsPanel') TermsAndContitionsPanel: any;
  @ViewChild('TravelFaqPanel') TravelFaqPanel: any;
  @ViewChild('GetInTouchPanel') GetInTouchPanel: any;
  searchData$ = this.activatedRoute.queryParams;

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.searchData$.subscribe(({ openSection }) => {
      switch (openSection) {
        case this.panels.termsAndContitionsPanel: {
          this.TermsAndContitionsPanel.open();
          break;
        }
        case this.panels.travelFaq: {
          this.TravelFaqPanel.open();
          break;
        }
        case this.panels.getInTouch: {
          this.GetInTouchPanel.open();
          break;
        }
      }
    });
  }
}