import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Package, RoomType } from '../../../models';
import * as _ from "lodash";
import { RoomDialogComponent } from '../../room-dialog/room-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'namu-booking-summary-v2',
    templateUrl: './booking-summary.component.html',
    styleUrls: ['./booking-summary.component.scss']
})



export class BookingSummaryV2Component implements OnInit {
    constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog,) { }
    
    package: Package;

    @Input() set packages(packages: Package[]){
        console.log(packages);
        
        if (packages) {
            this.package = packages[0];
        }
    };

    

    openRoomDetails(room: RoomType) {
        console.log("room:" + room.htmlDescription)
        const dialogRef = this.dialog.open(RoomDialogComponent, {
            width: "70%",
            data: room
        });
    };
    
    ngOnInit() {}
}