import { Product, Traveler } from '.';
import { Tour } from '.';
import { Moment } from 'moment';


export class Invoice {
    id: number;
    arrivalDate: Moment;
    departureDate: Moment;
    adultsCount: Number;
    childrenCount: Number;
    templateId: Number;
    products: Product[];
    tours: Tour[]
    travelers: Traveler[];
    specialRequest: String;
    requireAirportTransportation: boolean
    termsAccepted: boolean;
    totalRackPrice: number;

    constructor(templateId: number, arrivalDate: Moment, departureDate: Moment, adultsCount: Number, childrenCount: Number,
        specialRequest: String, requireAirportTransportation: boolean, termsAccepted: boolean, totalRackPrice: number,
        products: Product[], tours: Tour[], travelers: Traveler[]) {
        this.templateId = templateId;
        this.products = products;
        this.tours = tours;
        this.travelers = travelers;
        this.arrivalDate = arrivalDate;
        this.departureDate = departureDate;
        this.adultsCount = adultsCount;
        this.childrenCount = childrenCount;
        this.specialRequest = specialRequest;
        this.requireAirportTransportation = requireAirportTransportation;
        this.termsAccepted = termsAccepted;
        this.totalRackPrice = totalRackPrice;
     }
}
