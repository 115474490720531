<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step>
    <ng-template matStepLabel>{{'titles.booking-summary' | translate}}</ng-template>
    <namu-booking-summary-v2 [packages]="(packages$ | async)"></namu-booking-summary-v2>
    <div class="booking-summary-actions row">
      <div class="col s12 m6 offset-m1">
        <div class="row">
          <div class="col m11 offset-m2">
            <div class="row">
              <div class="col m12 offset-m3 s12 center">
                <button type="submit" mat-raised-button class="book-now blue darken-3" matStepperNext>BOOK NOW</button>
              </div>
              <div class="col m12 offset-m3 s12 center">
                <button type="submit" mat-raised-button class="change-search" (click)="changeSearch()">CHANGE
                  SEARCH</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-step>
  <mat-step [stepControl]="personalInformationForm">
    <ng-template matStepLabel>{{'titles.personal-information' | translate}}</ng-template>
    <div class="col s12 detail-data">
      <form [formGroup]="personalInformationForm" (ngSubmit)="saveChanges()" *ngIf="isFormCreated" >
        <namu-personal-information [personalInformationForm]="personalInformationForm">
        </namu-personal-information>
        <div class="row pay-section">
          <button type="submit" mat-raised-button mat-button
            class="continue-process blue darken-3">{{'labels.continue' | translate | uppercase }}
          </button>
        </div>
        <div class="contact">
          <p>{{'texts.contact-sopport' | translate}}<br><b>{{'texts.number-phone' | translate}}</b></p>
          <img class="contact-icon" src="../../../../assets/icons/support.png" alt="">
        </div>
        <div>
          <button mat-stroked-button mat-button matStepperPrevious
            class="return-process">{{'labels.return-booking-summary' | translate | uppercase }}
          </button>
        </div>
      </form>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'titles.payment' | translate}}</ng-template>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'titles.confirmation' | translate}}</ng-template>
    <div *ngIf="showConfirmation">
      <namu-confirmation-view></namu-confirmation-view>
    </div>
  </mat-step>
</mat-horizontal-stepper>

<ng-template #chargingCardMsg>
  <div>
    <mat-spinner [diameter]="65" class="mat-spinner-reduced"></mat-spinner>
    <span>{{'texts.charging-card' | translate}}...</span>
  </div>
</ng-template>
<ng-template #sendingReservationsMsg>
  <div>
    <mat-spinner [diameter]="65" class="mat-spinner-reduced"></mat-spinner>
    <span>{{'texts.sending-reservations' | translate}}...</span>
  </div>
</ng-template>
<ng-template #success>
  <mat-dialog-content>
    <namu-success-animation></namu-success-animation>
    <span>{{'texts.purchase-completed' | translate}}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
  </mat-dialog-actions>
</ng-template>
<ng-template #error>
  <div class="modals-container">
    <mat-dialog-content>
      <namu-error-animation></namu-error-animation>
      <span class="front-text">{{'alerts.error-purchasing' | translate}}</span>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
    </mat-dialog-actions>
  </div>
</ng-template>