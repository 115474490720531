import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Package } from '../../models';
import { BootstrapOptions } from '@angular/core/src/application_ref';

@Component({
    selector: 'namu-package-list',
    templateUrl: './package-list.component.html',
    styleUrls: ['./package-list.component.scss']
})

export class PackageListComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute) { }

    @Input() packages: Package[];
    @Input() isLoading: Boolean[];
    @Input() packageId: number;

    ngOnInit() {
        
    }
}