<h4 mat-dialog-title><b>{{tour.name}}</b></h4>
<mat-dialog-content>
    <div class="row">
        <div class="col m12">
            <div class="text" [innerHTML]="tour.htmlDescription"></div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
</mat-dialog-actions>