<div class="loading" *ngIf="isLoading">
    <div class="spinner-container">
        <mat-spinner [diameter]="65" class="mat-spinner-reduced results-spinner"></mat-spinner>
        <span>{{ 'texts.waiting-packages-msg' | translate }}</span>
    </div>
</div>
<div *ngIf="!isLoading">
    <div *ngFor="let package of packages" class="package-item">
        <namu-package [package]="package"></namu-package>
    </div>
</div>