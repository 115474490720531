import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RequestMethod, RequestOptionsArgs, Response, URLSearchParams } from "@angular/http";


/**
 * This service handle all the application http requests, it verifies if JWT token is valid, otherwise tries to refresh it or request user interaction to get a new token
 */
@Injectable()
export class HttpClientService {
    constructor(
        private http: HttpClient,
    ) { }

    /**
     * Send GET request to server
     * @param url
     * @param search
     */
    public get(url: string, search?: URLSearchParams): Observable<any> {
        return this.request(url, { method: RequestMethod.Get, search });
    }

    /**
     * Send POST request to server
     * @param url
     * @param data
     */
    public post(url: string, data?: Object): Observable<any> {
        return this.request(url, { method: RequestMethod.Post }, data);
    }

    /**
     * Send PUT request to server
     * @param url
     * @param data
     */
    public put(url: string, data?: Object): Observable<any> {
        return this.request(url, { method: RequestMethod.Put }, data);
    }

    /**
     * Send DELETE request to server
     * @param url
     */
    public delete(url: string): Observable<any> {
        return this.request(url, { method: RequestMethod.Delete });
    }

    private request(
        url: string,
        options: RequestOptionsArgs,
        data?: any
    ): Observable<Response> {
        if (data) {
            options.body = JSON.stringify(data);
        }

        return this.performHttpRequest(url, options);
    }

    private performHttpRequest(url, options): Observable<any> {
        options.headers = this.getHeaders();
        return this.http.request(RequestMethod[options.method], url, options);
    }

    private getHeaders() {
        return new HttpHeaders({
            "Content-Type": "application/json"
        });
    }
}
