import { Component, Inject, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RatePlan } from '../../models';

@Component({
    selector: 'namu-rateplan-dialog',
    templateUrl: './rateplan-dialog.component.html',
    styleUrls: ['./rateplan-dialog.component.scss']
})

export class RateplanDialogComponent implements OnInit {
    ratePlan: RatePlan;

    constructor(private activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<RateplanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RatePlan) {
        this.ratePlan = data;
    }

    ngOnInit() {
    }

    closeDialog(action) {
        this.dialogRef.close({ action: action, ratePlan: this.ratePlan });
    }
}