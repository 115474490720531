export const DATE_FORMAT = {
    client: "MMM dd, yyyy",
    server: "MM-DD-YYYY"
  };
  

  export class AppSettings {
    apiUrl: string;
  }
  
  export const appSettingsValues: AppSettings = {
    apiUrl: "onlinebooking-api",
  };

  export const stripeKey = "pk_live_YACwlzMyarjRnjl1POzc4xFw";
