import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Package } from '../../models';
import { Subscription } from 'rxjs';
import { ComponentUtilities } from '../../utilities/component-utilities';
import { DATE_FORMAT } from '../../app.settings';
import moment from 'moment-es6';

@Component({
    selector: 'namu-package',
    templateUrl: './package.component.html',
    styleUrls: ['./package.component.scss']
})

export class PackageComponent implements OnInit, OnDestroy {
    readingMore: boolean;
    innerWidth: number;
    private util = new ComponentUtilities();

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    }

    public _package: Package;
    private routeSubscription: Subscription;

    @Input() public set package(_package: Package) {
        this._package = _package;
    }

    @HostListener('window:resize') onResize(event) {
        if (this.innerWidth == window.innerWidth)
            return;

        this.innerWidth = window.innerWidth;

        if (this.innerWidth <= 601) {
            this.readingMore = false;
        }
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
    }

    ngOnDestroy() {
        if (this.routeSubscription) this.routeSubscription.unsubscribe();
    }

    isReadingMore() {
        if (this.innerWidth > 601) {
            return true;
        }
        else {
            return this.readingMore
        }
    }

    toggleReadMore() {
        this.readingMore = !this.readingMore;
    }

    redirectToBooking(templateId: number) {
        var useDefaultCriteria = false;
        var defaultCriteria = this.util.getDefaultSearchCriteria();

        this.routeSubscription = this.activatedRoute.queryParams.subscribe((params: Params) => {
            console.log(params);
            if (!params.end && !params.start) useDefaultCriteria = true;

            var paramsStart = moment(params.start, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);
            var paramsEnd = moment(params.end, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);

            this.router.navigate(['booking'], {
                queryParams: {
                    start: useDefaultCriteria ? defaultCriteria.start.format(DATE_FORMAT.server) : paramsStart.format(DATE_FORMAT.server),
                    end: useDefaultCriteria ? defaultCriteria.end.format(DATE_FORMAT.server) : paramsEnd.format(DATE_FORMAT.server),
                    adults: useDefaultCriteria ? defaultCriteria.adults : params.adults,
                    rooms: useDefaultCriteria ? defaultCriteria.rooms : params.rooms,
                    templateId: templateId,
                    source: params.source
                }
            })

        });
    }
}