import {
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        MatStepperModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatRadioModule,
        MatSortModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatChipsModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatGridListModule
} from '@angular/material';
import { NgModule } from '@angular/core';

@NgModule({
        exports: [MatButtonModule,
                MatCheckboxModule,
                MatIconModule,
                MatButtonToggleModule,
                MatStepperModule,
                MatInputModule,
                MatSelectModule,
                MatAutocompleteModule,
                MatSnackBarModule,
                MatProgressSpinnerModule,
                MatProgressBarModule,
                MatDialogModule,
                MatDatepickerModule,
                MatTooltipModule,
                MatCardModule,
                MatRadioModule,
                MatTableModule,
                MatSortModule,
                MatTabsModule,
                MatSlideToggleModule,
                MatMenuModule,
                MatSidenavModule,
                MatChipsModule,
                MatExpansionModule,
                MatPaginatorModule,
                MatGridListModule],
})
export class MaterialComponentsModule { }
