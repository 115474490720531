<h4 mat-dialog-title><b>{{'labels.amenities' | translate}}</b></h4>
<mat-dialog-content>
    <span class="amenities" *ngFor="let amenity of amenities">
        <div class="row amenity-row">
            <div class="col s2">
                <mat-icon class="icon">{{amenity.icon}}
                </mat-icon>
            </div>
            <div class="col s10"><span class="amenity-text">{{amenity.name}}</span>
            </div>
        </div>
    </span>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
</mat-dialog-actions>