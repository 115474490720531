import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'namu-success-animation',
    template: `<div class="success-checkmark">
                <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
                </div>
            </div>`,
    styleUrls: ['success-animation.component.scss']
})

export class SuccessAnimationComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}