import moment from 'moment-es6';


export class SearchData {

    constructor(_start: moment.Moment = undefined,
        _end: moment.Moment = undefined,
        _adults: number = undefined,
        _rooms: number = undefined,
        _templateId: number = undefined, _source:string = undefined) {
            this.start = moment(_start, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);
            this.end = moment(_end, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);
            this.adults = _adults;
            this.rooms = _rooms;
            this.templateId = _templateId;
            this.source = _source;
    }

    start: moment.Moment;
    end: moment.Moment;
    adults: number;
    rooms: number;
    templateId: number;
    source: string;
}