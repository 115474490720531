import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Tour } from '../../models/tour';
import { TourDialogComponent } from '../tour-dialog/tour-dialog.component';

@Component({
    selector: 'namu-tour',
    templateUrl: './tour.component.html',
    styleUrls: ['./tour.component.scss']
})

export class TourComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog) { }
    @Input() tour: Tour;

    ngOnInit() {
        
    }

    public getArray(length: number){
        return new Array(length);
    }

    openTourDetails(tour: Tour) {
        const dialogRef = this.dialog.open(TourDialogComponent, {
            width: "70%",
            data: tour
          });
    };
}