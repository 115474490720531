import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'namu-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})

export class IntroductionComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }
  
  ngOnInit() {
  }
}