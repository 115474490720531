import { DATE_FORMAT } from './../../app.settings';
import { Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import moment from 'moment-es6';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchData } from '../../models';
import { ComponentUtilities } from '../../utilities/component-utilities';

@Component({
    selector: 'namu-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnDestroy {
    private startSubscription: Subscription;
    private util = new ComponentUtilities();
    private defaultSearchData = this.util.getDefaultSearchCriteria();
    private searchData$ = this.activatedRoute.queryParams;

    isTinyMobile: boolean = false;

    @Input() isChanging: boolean;
    searchForm: FormGroup;

    @Input() orientation = "horizontal";
    @Input() action: string = "landing";
    private source: string;
    @Input() set data(value: SearchData) {
        if(!value) return;
        this.setUpForm();
        if (value.start && value.end){
            this.searchForm.patchValue({
                dateRange:{
                    start: moment(value.start, DATE_FORMAT.server),
                    end: moment(value.end,  DATE_FORMAT.server)
                },
                adults: value.adults,
                rooms: value.rooms
            });
        }
        this.searchForm.markAsDirty();
    }

    constructor(private fb: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute) {}

    mask = {
        date: {
            guide: true,
            showMask: true,
            mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
        }
    }

    private startControl = this.fb.control(this.defaultSearchData.start, Validators.required);
    private endControl = this.fb.control(this.defaultSearchData.end, Validators.required);

    private searchHash(length) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      
        for (var i = 0; i < length; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
      }

    redirectToSearch() {
        if (this.searchForm.valid) {
            const { dateRange, adults, rooms } = this.searchForm.value;

            dateRange.start = moment(dateRange.start);
            dateRange.end = moment(dateRange.end);

            this.router.navigate(['search'], {
                queryParams: {
                    start: dateRange.start.format(DATE_FORMAT.server),
                    end: dateRange.end.format(DATE_FORMAT.server),
                    adults,
                    rooms,
                    source: this.source,
                    hash: this.searchHash(10)
                }
            })
        }
    }

    setUpForm(){
        if(this.searchForm) return;
        this.searchForm = this.fb.group({
            dateRange: this.fb.group({
                start: this.startControl,
                end: this.endControl
            }),
            adults: [this.defaultSearchData.adults, Validators.min(1)],
            rooms: [this.defaultSearchData.rooms, Validators.min(1)]
        });
    }

    ngOnInit() {
        this.setUpForm();
        this.startSubscription = this.startControl.valueChanges.subscribe((val: moment.Moment) => {
            if(moment(this.searchForm.value.dateRange.end, DATE_FORMAT.server).subtract(4, 'd') > moment(val, DATE_FORMAT.server)) return;
            
            this.searchForm.patchValue({
                dateRange: {
                    end: val.clone().add(4, 'days')
                }
            });
        });
        
        this.setTinyMobile();

        this.searchData$.subscribe(({ source }) => {
            this.source = source;
        });
    }

    toggleChangeSearch() {
        this.isChanging = !this.isChanging;
    }

    ngOnDestroy(): void {
        if (this.startSubscription) this.startSubscription.unsubscribe();
    }

    @HostListener('window:resize') onResize(event) {
        this.setTinyMobile();
    }

    setTinyMobile() {
        if (window.innerWidth <= 386) {
            this.isTinyMobile = true;
        } else {
            this.isTinyMobile = false;
        }
    }
}