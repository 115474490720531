<h4 mat-dialog-title>{{'terms-and-conditions.terms-title' | translate}}</h4>
<mat-dialog-content>
    <div class="row text">
        <div class="col m12">
            <p>{{'terms-and-conditions.terms-body' | translate}}</p>
            <h6>{{'terms-and-conditions.payment-policy-title' | translate}}</h6>
            <p>{{'terms-and-conditions.payment-polify-body' | translate}}</p>
            <h6>{{'terms-and-conditions.traveling-dates-and-seasons-title' | translate}}</h6>
            <div [innerHtml]="'terms-and-conditions.traveling-dates-and-seasons-body' | translate"></div>
            <h6>{{'terms-and-conditions.changes-on-travel-dates-title' | translate}}</h6>
            <div [innerHtml]="'terms-and-conditions.changes-on-travel-dates-body' | translate"></div>
            <h6>{{'terms-and-conditions.cancellation-schedule-penalties-title' | translate}}</h6>
            <div [innerHtml]="'terms-and-conditions.cancellation-schedule-penalties-body' | translate"></div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="closeDialog(false)">{{'labels.cancel' | translate}}</button>
    <button mat-button cdkFocusInitial [mat-dialog-close]="true"
        (click)="closeDialog(true)">{{'labels.accept' | translate}}</button>
</mat-dialog-actions>