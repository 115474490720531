<h4 mat-dialog-title><b>{{room.roomTypeName}}</b></h4>
<mat-dialog-content>
    <div class="row">
        <div class="col s12 xl7">
            <div class="text" [innerHTML]="room.htmlDescription"></div>
        </div>
        <div class="col s12 xl5">
            <img class="responsive-img room-image" src="{{room.imagePath}}">
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
</mat-dialog-actions>