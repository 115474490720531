import { Component, Inject, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoomType } from '../../models';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'namu-room-dialog',
    templateUrl: './room-dialog.component.html',
    styleUrls: ['./room-dialog.component.scss']
})

export class RoomDialogComponent implements OnInit {
    room: RoomType;

    constructor(private activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<RoomDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RoomType) {
        this.room = data;
    }

    ngOnInit() {
    }

    closeDialog(action) {
        this.dialogRef.close({ action: action, room: this.room });
    }
}