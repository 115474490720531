import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../../models';

@Component({
    selector: 'namu-hotel-list',
    templateUrl: './hotel-list.component.html',
    styleUrls: ['./hotel-list.component.scss']
})

export class HotelListComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute) { }
    @Input() packageDescription: string;
    @Input() packageId: string;
    @Input() isSoldOut: boolean;
    @Input() products: Product[];
    @Input() pax: number;

    ngOnInit() {
    }
}