import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Package } from '../models/package';
import { AppSettings, DATE_FORMAT } from '../app.settings';
import { HttpClientService } from './http-client.service';
import { Observable } from 'rxjs';
import moment from 'moment-es6';
import { InvoiceContract } from './invoice-service.contract';
import { Traveler, Invoice } from '../models';

@Injectable()
export class InvoiceService extends InvoiceContract {

  constructor(private httpService: HttpClientService, private appSettings: AppSettings) {
    super();
  }

  private endpoint: string = `${this.appSettings.apiUrl}/Invoices`;

  createInvoice(invoice: Invoice): Observable<any> {
    return this.httpService.post(`${this.endpoint}`, invoice);
  }

  applyCharge(invoiceId:number, tokenId: string, amount: number, description: string): Observable<any> {
    return this.httpService.post(
      `${this.endpoint}/${invoiceId}/Payment`,
      {
        tokenId, amount, description
      }
    );
  }
}
