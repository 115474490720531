<namu-search-bar action="search" isSearchVisible="true">
  <namu-package-list [packages]="packages" [isLoading]="isLoading"
    *ngIf="(packages?.length > 0 || isLoading); else noResults">
  </namu-package-list>
  <namu-footer *ngIf="!isLoading"></namu-footer>
</namu-search-bar>
<ng-template #noResults>
  <mat-card class="no-data-card">
    <mat-card-header class="no-data-card-header">
      <div class="row">
        <div class="col m12">
          <p class="center">{{ 'alerts.no-packages' | translate }}</p>
        </div>
      </div>
    </mat-card-header>
  </mat-card>
</ng-template>

<ng-template #invalidTemplateId>
  <mat-dialog-content>
    <span>{{'texts.package-not-available' | translate}}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="accent">{{'labels.close' | translate}}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #soldOutMessage>
    <mat-dialog-content>
      <span>{{'texts.package-is-sold-out' | translate}}</span>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="accent">{{'labels.close' | translate}}</button>
    </mat-dialog-actions>
  </ng-template>
