import { Component, Inject, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Tour } from '../../models';

@Component({
    selector: 'namu-tour-dialog',
    templateUrl: './tour-dialog.component.html',
    styleUrls: ['./tour-dialog.component.scss']
})

export class TourDialogComponent implements OnInit {
    tour: Tour;

    constructor(private activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<TourDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Tour) {
        this.tour = data;
    }

    ngOnInit() {
    }

    closeDialog(action) {
        this.dialogRef.close({ action: action, tour: this.tour });
    }
}