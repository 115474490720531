import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private queryParams$ = this.activatedRoute.queryParams;
  private source: string;

  constructor(private translate: TranslateService,private router: Router, private activatedRoute: ActivatedRoute) {
    translate.setDefaultLang('en');
  }

  public redirectToPage(){
    this.router.navigate(['/'],  {
      queryParams: {
        source: this.source
      }
    })
  }

  ngOnInit(){
    this.queryParams$.subscribe(({ source }) => {
      this.source = source;
    });
  }
}
