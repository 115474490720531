import { Observable } from 'rxjs';
import { Package } from '../models/package';
import { HttpHeaders } from "@angular/common/http";
import moment from 'moment-es6';
import { SpecialRequest } from '../models';

export abstract class PackageContract {
    abstract searchPackages(start: moment.Moment, end: moment.Moment, rooms: number, adultsCount: number, orderByTemplateId: number, headers?: HttpHeaders): Observable<Package[]>;
    abstract getPackageByTemplateId(start: moment.Moment, end: moment.Moment, rooms: number, adultsCount: number, templateId: number, orderByTemplateId: number, headers?: HttpHeaders): Observable<Package[]>;
    abstract sendReservations(invoiceId: Number): Observable<boolean>;
}
