import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tour } from '../../models';

@Component({
    selector: 'namu-tour-list',
    templateUrl: 'tour-list.component.html',
    styleUrls: ['tour-list.component.scss']
})

export class TourListComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute) { }

    @Input() tours: Tour[];

    ngOnInit() {
        
    }

    
}