import { Component, Inject, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Amenity } from '../../../models';

@Component({
    selector: 'namu-amenities-dialog',
    templateUrl: './amenities-dialog.component.html',
    styleUrls: ['./amenities-dialog.component.scss']
})

export class AmenitiesDialogComponent implements OnInit {
    amenities: Amenity[];

    constructor(private activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<AmenitiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Amenity[]) {
        this.amenities = data;
    }

    ngOnInit() {
    }

    closeDialog(action) {
        this.dialogRef.close({ action: action, amenities: this.amenities });
    }
}