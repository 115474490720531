import { Observable } from 'rxjs';
import { Package } from '../models/package';
import { HttpHeaders } from "@angular/common/http";
import moment from 'moment-es6';
import {Invoice } from '../models';

export abstract class InvoiceContract {
    abstract createInvoice(traveler: Invoice): Observable<any>;
    abstract applyCharge(invoiceId: number, tokenId: string, amount: number, description: string): Observable<any>;
}
