import { Injectable } from '@angular/core';
import { StripeCheckoutLoader, StripeCheckoutHandler } from 'ng-stripe-checkout';
import { stripeKey } from '../app.settings';
import { StripeToken } from '../models';
import { Observable, from } from 'rxjs';

@Injectable()
export class StripeService {

  constructor(private stripeCheckoutLoader: StripeCheckoutLoader) { }

  private stripeCheckoutHandler: StripeCheckoutHandler;
  private initCompleted = false;

  init() {
    this.stripeCheckoutLoader.createHandler({
      key: stripeKey,
    }).then((handler: StripeCheckoutHandler) => {
      this.stripeCheckoutHandler = handler;
      this.initCompleted = true;
    });
  }

  public openPayPopup(email: string, amount: number): Observable<StripeToken> {
    if (!this.initCompleted) {
      console.warn("Before try to open stripe modal must call init in ngAfterInit hook");
      return;
    }
    return from(this.stripeCheckoutHandler.open({
      amount: amount * 100,
      currency: 'USD',
      email,
      image: "https://cdn.centralamerica.com/wp-content/themes/wanderlust/assets/images/favicon192.png"
    }) as Promise<StripeToken>);
  }
}