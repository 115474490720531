<div class="row card-header" *ngIf="package">
    <div class="col s12 card-header-container blue-grey darken-2">
        <p class="card-header-text hide-on-med-and-up">{{'titles.booking-summary' | translate | uppercase}}</p>
        <p class="center hide-on-med-and-up"><span>
            <span class="pkg-price">${{package.totalRackPrice | number:'1.0-0' }} </span>
            <span class="total-price-lbl" [matTooltip]="('texts.price-explanation1' | translate)+((package.tours||[]).length)+('texts.price-explanation2' | translate)+((package.tours||[]).length > 0 ? package.tours[0].adultsCount : 0)+('texts.price-explanation3' | translate)"
                matTooltipPosition="left"><u>{{ 'labels.total-pkg-price-low' | translate }}</u></span></span></p>

                <p class="card-header-text hide-on-small-only">{{'titles.booking-summary' | translate | uppercase}}
                    <span class="card-header-text-wrapper">
                        <span class="pkg-price">${{package.totalRackPrice | number:'1.0-0' }} </span>
                        <span class="total-price-lbl" [matTooltip]="('texts.price-explanation1' | translate)+((package.tours||[]).length)+('texts.price-explanation2' | translate)+((package.tours||[]).length > 0 ? package.tours[0].adultsCount : 0)+('texts.price-explanation3' | translate)"
                            matTooltipPosition="left"><u>{{ 'labels.total-pkg-price-low' | translate }}</u></span></span></p>
    </div>
</div>
<mat-card class="card" *ngIf="package">
    <mat-card-content>
        <div class="row">
            <div class="col m6 detail-data" *ngFor="let product of package.products;">
                <h6 class="title">{{product.hotelName}} ({{product.locationName}})</h6>
                <div *ngFor="let room of product.roomTypes; let i = index">
                    <p>
                        <li>{{room.numberOfRooms}} x {{room.roomTypeName}} room(s) x {{room.adultsCount}}
                            {{'labels.adults' | translate}}</li>
                    </p>
                </div>
                <div *ngFor="let room of product.roomTypes; let i = index">
                    <p *ngIf="i == 0">
                        <li>{{'labels.check-in' | translate}}: {{product.startDate | date: 'MMM dd, yyyy'}}
                            {{'labels.from-time' | translate}} {{product.checkInTime | date: 'h:mm a'}}</li>
                    </p>
                    <p *ngIf="i == 0">
                        <li>{{'labels.check-out' | translate}}: {{product.endDate | date: 'MMM dd, yyyy'}}
                            {{'labels.from-time' | translate}} {{product.checkOutTime | date: 'h:mm a'}}</li>
                    </p>
                    <p *ngIf="i == 0">
                        <li>{{product.ratePlans[0].ratePlanName}}</li>
                    </p>
                </div>
            </div>
            <div class="col m6 detail-data">
                <h6 class="title">{{'titles.your-activities' | translate}}</h6>
                <div *ngFor="let tour of package.tours; let rowIndex=index">
                    <p>
                        <li>{{'labels.on' | translate }} {{tour.date | date: 'MMM dd, yyyy'}}</li>
                    </p>
                    <p>
                        {{tour.name}}: {{tour.adultsCount}} {{'labels.adults' | translate}}
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>