<footer class="blue-grey darken-2">
    <div class="container">
        <div class="row">
            <div class="col m7 s12">
                <h5 class="about-central-america">{{'labels.about' | translate}} <b>travel.central</b>america.com</h5>
                <p class="text-about-central-america">{{'texts.about-central-america' | translate}}</p>
                <div class="row">
                    <div class="col m6 ">
                        <div>
                            <ul class="footer-links">
                                <li>
                                    <a href="https://www.centralamerica.com/about-us/">
                                        <fa name="chevron-right" size="1x" class="icon-include footer-icon"></fa>
                                        {{'labels.about-us' | translate}}
                                    </a>
                                </li>
                                <li (click)="redirectToFaqPage('GetInTouchPanel')">
                                    <fa name="chevron-right" size="1x" class="icon-include footer-icon"></fa>
                                    {{'labels.contact-us' | translate}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col m6 s12">
                        <div>
                            <ul class="footer-links">
                                <li (click)="redirectToFaqPage('TermsAndContitionsPanel')">
                                    <fa name="chevron-right" size="1x" class="icon-include footer-icon"></fa>
                                    {{'labels.terms-and-conditions-camelcase' | translate }}
                                </li>
                                <li (click)="redirectToFaqPage('TravelFaqPanel')">
                                    <fa name="chevron-right" size="1x" class="icon-include footer-icon"></fa>
                                    {{'labels.travel-information' | translate }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col m5 social-media s12">
                <div class="second-section">
                    <h5>{{'labels.follow-us' | translate }}</h5>
                    <div>
                        <a href="https://www.facebook.com/centralamerica/">
                            <fa name="facebook" size="1x" class="icon-include footer-icon"></fa>
                        </a>
                        <a href="https://twitter.com/VidaAmerica">
                            <fa name="twitter" size="1x" class="icon-include footer-icon"></fa>
                        </a>
                        <a href="https://www.instagram.com/centralamericaliving">
                            <fa name="instagram" size="1x" class="icon-include footer-icon"></fa>
                        </a>
                        <a
                            href="https://www.tripadvisor.com/Attraction_Review-g309240-d17656529-Reviews-CentralAmerica_com-Liberia_Province_of_Guanacaste.html">
                            <fa name="tripadvisor" size="1x" class="icon-include footer-icon"></fa>
                        </a>
                    </div>
                    <br>
                    <a href="http://www.namutravel.com">
                        <img src="../../../../assets/namu-logo-2.png" alt="">
                    </a>
                </div>

            </div>
        </div>
    </div>
    <div class="copyright-section">
        <p>{{'texts.about-central-america-network' | translate}}<br>
            <span class="copyright-label">Copyright © 2019 – </span> 
            <a href="https://www.centralamerica.com/privacy-policy/">{{'labels.privacy-policy' | translate}} </a>
        </p>
    </div>
</footer>