import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'namu-landing-page',
    templateUrl: 'landing-page.component.html',
    styleUrls: ['landing-page.component.css']
})

export class LandingPageComponent implements OnInit {
    constructor(private snackBar: MatSnackBar,
        private router: Router) {
    }

    private helpSnackBar;

    goToIntroductionPage(){
        this.router.navigate(['introduction'])
    }

    ngOnInit() {
        this.helpSnackBar.onAction().subscribe(() => {
            this.goToIntroductionPage();
          });
    }
}