<div class="faq-container">
    <mat-accordion class="accordion">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="main-section">Manage My Booking</mat-panel-title>
                <div class="description">
                    <mat-panel-description>Change dates or cancel your booking</mat-panel-description>
                </div>
            </mat-expansion-panel-header>
            <div class="divider"></div>
            <p>For assistance making changes or to cancel your booking, please contact our support team at</p>
            <p class="indent-l1">Email: <a href="mailto:support@centralamerica.com">support@centralamerica.com</a>
            </p>
            <p>Please consider charges may apply to your change or cancellation requests.</p>
            <p>Changes are subject to hotel’s availability.</p>
            <p>Your email will be responded within 24 hours after it is received. Please consider our team handles
                requests as they arrive.</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="main-section">Contact your service providers</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="divider"></div>
            <p>If you have any questions regarding your service provider, they usually know best. You can find their
                contact details in your confirmation email.</p>
        </mat-expansion-panel>
        <mat-expansion-panel #GetInTouchPanel>
            <mat-expansion-panel-header>
                <mat-panel-title class="main-section">Get in touch</mat-panel-title>
                <div class="description">
                    <mat-panel-description>How to contact our team</mat-panel-description>
                </div>
            </mat-expansion-panel-header>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Before your vacation</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>You may contact our Support Team at</p>
                <p class="indent-l1">Phone Number: <a href="tel:18006089887">1-800-608-9887</a></p>
                <p class="indent-l1">Hours: 8 am to 5pm - Monday through Sunday</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>During or after your vacation</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>You may contact our In country Support Team at</p>
                <p class="indent-l1">Email: <a href="mailto:support@centralamerica.com">support@centralamerica.com</a>
                </p>
                <p>Your email will be responded within 24 hours after it is received. Please consider our team handles
                    requests as they arrive.</p>
                <p><i>For any concern that requires immediate assistance, please contact your service provider directly.
                        Their contact information can be found in your confirmation email.</i></p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Who should I call if my flight is delayed or cancelled?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>Our transportation service provider keeps track of flight changes or delays.</p>
                <p>If your flight number changes, please contact your transportation provider. Their contact information
                    can be found in your transportation confirmation email.</p>
                <p>If for any reason you are unable to come to Costa Rica at all, please contact our support team at <a
                        href="mailto:support@centralamerica.com">support@centralamerica.com</a></p>
                <p>Your email will be responded within 24 hours after it is received. Please consider our team handles
                    requests as they arrive.</p>
            </mat-expansion-panel>
        </mat-expansion-panel>
        <mat-expansion-panel #TravelFaqPanel>
            <mat-expansion-panel-header>
                <mat-panel-title class="main-section">Travel FAQ</mat-panel-title>
                <div class="description">
                    <mat-panel-description>Helpful tips for your vacation</mat-panel-description>
                </div>
            </mat-expansion-panel-header>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>What are the identification and entry requirements to Costa Rica?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>To enter Costa Rica a valid passport and a pre-paid airline ticket
                    exiting the country is mandatory.
                    Additional specific entry requirements depend on your country of citizenship. Immigration will
                    then
                    stamp your passport with your approved length of stay (usually 90 days). It is always best to
                    <b>contact the Costa Rica Embassy nearest you for up to date entry and visa requirements</b>.
                    Link
                    to Costa Rica Consulate in the USA <a href="http://www.costarica-embassy.org/index.php?q=node/72"
                        target="_blank">click here</a>. We cannot be
                    responsible for clients traveling without proper
                    Visa or Passport requirements.</p>
            </mat-expansion-panel>
            <mat-expansion-panel invalidTemplateId>
                <mat-expansion-panel-header>
                    <mat-panel-title>Do I need shots to go to Costa Rica?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>If you are coming from the US or Canada, you are not required to get any vaccinations. However,
                    we
                    always recommend discussing any concerns with your primary care physician. The Costa Rican
                    authorities require that all travelers coming from the following countries have a yellow fever
                    vaccination certificate: Angola, Benin, Burkina Faso, Cameroon, Democratic Republic of the
                    Congo,
                    Gabon, Gambia, Guinea, Liberia, Nigeria, Sierra Leone, Sudan, Bolivia, Venezuela, Brazil, Peru,
                    Colombia and Ecuador as well as the Republic of Guyana.</p>
                <p>The vaccine should be applied 10 days before entering the country. This
                    regulation will be enforced
                    on everyone except senior citizens, children under the age of 9 and pregnant women.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>How does the Phone System, Cell Phone Service work in Costa Rica?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>You can make International calls from most hotels. Most calls will have a fee, so do check with the
                    hotel for costs prior to making your call. You may access Skype on your laptop, smartphone, or Ipad
                    anywhere that there is Wifi. If you are carrying your personal cell phone, it’s best that you check
                    with your service provider in your home country to find out about connectivity and costs. Some phone
                    companies have very expensive fees for utilizing their service inside Costa Rica.</p>
                <p>Prepaid cell phones sim cards are now available in Costa Rica. In order for this service to work, you
                    must have a phone that is “unlocked” and has a replaceable SIM/GSM card. Check with your cell
                    provider first. A prepaid SIM chip is $20. The approximate value for a call to the US is US$0.30 per
                    minute.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>How much cash should I carry in Costa Rica?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>This is a personal choice based on your spending habits, but we recommend that you have at least
                    $100 cash with you upon arrival. (Only carry $1, $5, $10, $20 bills). How much cash you should carry
                    throughout the trip depends on your plans, if you like to shop, and if you prefer to use a
                    credit
                    card for purchases or not. In almost all destinations you will have access to an ATM Machine. We
                    recommend that you always place your cash, valuables, and passports in your hotel’s safe deposit
                    box
                    every time you leave your room.</p>
                <p>Costa Rica’s official currency is the Colón, however, U.S. dollars are still widely accepted
                    throughout the country.</p>
                <p>Mastercard and Visa are the most widely accepted. American Express is
                    accepted at most hotels and
                    some restaurants but not by local or small vendors. Discover Card is not accepted in Costa Rica.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Can I safely eat or drink the food and beverages I am served?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>Water is safe to drink throughout Costa Rica. However, we do recommend that you drink bottled
                    water
                    in remote areas. Bottled water is available at hotels, grocery stores, and restaurants. Do not
                    use
                    ice anywhere you would not drink the water! Keep in mind we all have different degrees of
                    delicacy
                    in our systems. If you are a person who often gets stomach distress when you travel, then it’s
                    best
                    to always choose bottled water.</p>
                <p>Food is normally safe in all the better hotels that cater to international travelers.</p>
                <p>In smaller “street restaurants” there is less certainty about the food
                    quality. Avoid eating uncooked
                    portions such as salads in such places. If you buy fresh fruits at a roadside stand or from a
                    street
                    vendor, wash the fruits carefully before eating them.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Is Costa Rica Safe?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p><b>Costa Rica is an extremely safe country</b>. It is very easy to let down your normal guard while
                    you
                    are here in Costa Rica enjoying the “Pura Vida” lifestyle, but it is important to take normal safety
                    precautions.</p>
                <p>
                    <li class="indent-l1">The largest crime in Costa Rica is petty theft so be diligent in watching
                        over
                        your personal items. Use your hotel safe at all times.</li>
                    <li class="indent-l1">Do not leave cameras, purses, Ipads, Ipods, and cell phones unattended on
                        restaurant tables, pool lounges, or beach towels.</li>
                    <li class="indent-l1">Leave expensive watches and jewelry at home.</li>
                    <li class="indent-l1">If you have a rental car, stow your personal belongings under the seat,
                        make
                        sure the car is locked and if possible park it in an area in which you can keep an eye on
                        it.
                    </li>
                    <li class="indent-l1">We always recommend you bring a photocopy of the
                        picture page of your passport as well.</li>
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>What is the emergency number in Costa Rica?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>The general emergency number <b>911</b> is available throughout the country. Dialing 911 enables you
                    to connect with medical, ambulance, police and fire services. Please note that the official language
                    of Costa Rica is Spanish and although the 911 operator might speak English it is not guaranteed!</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>How do I pay the exit tax when leaving Costa Rica?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="divider"></div>
                <p>The exit tax costs $31 per person. It’s better to have cash, either in US
                    Dollars or Colones (local
                    currency). They do accept Visa and Master Card (they do not accept American Express), but credit
                    card companies charge it as a “cash advance”, so you end up paying a lot in interest for a small
                    charge. Cash is best.
                    Note: The airport tax may or may not be included in your airfare. Please check with your airline
                    if
                    you need additional information.
                </p>
            </mat-expansion-panel>
        </mat-expansion-panel>
        <mat-expansion-panel #TermsAndContitionsPanel>
            <mat-expansion-panel-header>
                <mat-panel-title><b>Terms and Conditions</b></mat-panel-title>
            </mat-expansion-panel-header>
            <p><b>Terms and Conditions</b></p>
            <p>The purchase of any itinerary is subject to acceptance of the terms and conditions below. By
                purchasing any of the products or services included in your itinerary, you agree to be bound by
                these terms. All prices are quoted in United States Dollars (USD). By purchasing an itinerary, you
                acknowledge that you have agreed to accept all products and services included in the total price.
            </p>
            <br>
            <p><b>Payment Policy</b></p>
            <p>This payment may be made through credit card in the form of Visa, MasterCard or American Express. We
                are unable to accept bank transfers, payments through Paypal, and personal or cashier’s checks. Full
                payment is required at time of booking.</p>
            <br>
            <p><b>Traveling dates and seasons</b></p>
            <p>Fees and policies restrictions apply based on traveling dates. Seasons for 2019 - 2020 are detailed
                below:</p>
            <p><b>Low and High Season</b></p>
            <p>July 1st 2019 - December 19th 2019</p>
            <p>January 6th 2020 - April 2nd 2020</p>
            <p>April 12th 2020 - December 17th 2020</p>
            <p><b>Peak Season</b></p>
            <p>December 20st 2019 - January 5th 2020</p>
            <p>April 3rd 2020 - April 11th 2020</p>
            <br>
            <p><b>Changes on Travel Dates</b></p>
            <li>You are entitled to one change of dates for your package with no additional cost, when original
                booking is made at least 35-65 days before arrival, depending on season. Additional charges on the
                partner’s rates may apply.</li>
            <li>For additional changes on traveling dates, a US$60 fee will be applied. Additional charges on the
                partner’s rates may apply.</li>
            <li>Low & High Season: No changes permitted within 35 days prior to arrival.</li>
            <li>Peak Season: No changes permitted within 65 days prior to arrival.</li>
            <br>
            <p><b>Cancellation Schedule & Penalties</b></p>
            <li>Cancellations within 24 hours after booking will have no penalty.</li>
            <li>A package cancellation after 24 hours of booking will incur a penalty of 10% of the total package
                cost. The refund will be issued to the same credit card used in the original payment.</li>
            <li>Low & High Season: Full penalty for cancellation within 35 days prior to arrival.</li>
            <li>Peak Season: Full penalty for cancellation within 65 days prior to arrival.</li>
            <li>Cancellations made during your vacation will result in a 100% penalty.</li>
        </mat-expansion-panel>
    </mat-accordion>
</div>