<div *ngIf="showThereIsAvailabilityMessage">
  <namu-search-bar action="search" [isSearchVisible]="isSearchVisible">
    <namu-stepper (isSearchOpened)="isSearchVisible = $event"></namu-stepper>
    <namu-footer *ngIf="fullLoad"></namu-footer>
  </namu-search-bar>
  <ng-template #chargingCardMsg>
    <div>
      <mat-spinner [diameter]="65" class="mat-spinner-reduced"></mat-spinner>
      <span>{{'texts.charging-card' | translate}}...</span>
    </div>
  </ng-template>
  <ng-template #sendingReservationsMsg>
    <div>
      <mat-spinner [diameter]="65" class="mat-spinner-reduced"></mat-spinner>
      <span>{{'texts.sending-reservations' | translate}}...</span>
    </div>
  </ng-template>
  <ng-template #success>
    <mat-dialog-content>
      <namu-success-animation></namu-success-animation>
      <span>{{'texts.purchase-completed' | translate}}</span>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
    </mat-dialog-actions>
  </ng-template>
  <ng-template #error>
    <div class="modals-container">
      <mat-dialog-content>
        <namu-error-animation></namu-error-animation>
        <span class="front-text">{{'alerts.error-purchasing' | translate}}</span>
      </mat-dialog-content>
      <mat-dialog-actions align="center">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
      </mat-dialog-actions>
    </div>
  </ng-template>
</div>

<namu-search-bar action="search" *ngIf="!showThereIsAvailabilityMessage" [isSearchVisible]="isSearchVisible">
  <div class="row availability-message" *ngIf="showThereIsAvailabilityMessage">
    <br>
    <div class="col s12 center">
      <mat-card class="message">
        <mat-card-content>
          <p>
            You're in luck! room(s) at {{(packages$ | async)[0].products[0].hotelName}} are available for your travel
            dates.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <form [formGroup]="customerDetailsForm" (ngSubmit)="saveChanges()" *ngIf="package">
    <namu-booking-summary [packages]="(packages$ | async)"></namu-booking-summary>
    <namu-customer-booking-details [customerDetailsForm]="customerDetailsForm"></namu-customer-booking-details>
    <div class="row pay-section">
      <div class="col s12">
        <button type="submit" mat-raised-button
          class="proceed-to-pay blue darken-3">{{'labels.proceed-to-pay' | translate | uppercase }}</button>
      </div>
    </div>
  </form>
  <ng-template #chargingCardMsg>
    <div>
      <mat-spinner [diameter]="65" class="mat-spinner-reduced"></mat-spinner>
      <span>{{'texts.charging-card' | translate}}...</span>
    </div>
  </ng-template>
  <ng-template #sendingReservationsMsg>
    <div>
      <mat-spinner [diameter]="65" class="mat-spinner-reduced"></mat-spinner>
      <span>{{'texts.sending-reservations' | translate}}...</span>
    </div>
  </ng-template>
  <ng-template #success>
    <mat-dialog-content>
      <namu-success-animation></namu-success-animation>
      <span>{{'texts.purchase-completed' | translate}}</span>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
    </mat-dialog-actions>
  </ng-template>
  <ng-template #error>
    <div class="modals-container">
      <mat-dialog-content>
        <namu-error-animation></namu-error-animation>
        <span class="front-text">{{'alerts.error-purchasing' | translate}}</span>
      </mat-dialog-content>
      <mat-dialog-actions align="center">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'labels.close' | translate}}</button>
      </mat-dialog-actions>
    </div>
  </ng-template>
  <namu-footer *ngIf="fullLoad"></namu-footer>
</namu-search-bar>