import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material';

@Component({
  selector: 'namu-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


export class FooterComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {

  }

  redirectToFaqPage(section) {
    this.router.navigate(['faq'], {
      queryParams: {
        openSection: section
      }
    })
  }
}