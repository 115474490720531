import { BookingContract } from './booking-service.contract';
import { Invoice } from '../models';
import {Injectable} from '@angular/core';

@Injectable()
export class BookingService extends BookingContract {
    private invoiceObject: Invoice;

    constructor() {
        super();
    }

    setInvoiceObject(invoice: Invoice) {
        this.invoiceObject = invoice;
    }

    getInvoiceObject(): Invoice {
        return this.invoiceObject;
    }
}
