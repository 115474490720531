<div class="blue-grey darken-1"
  [ngClass]="{ 'full-height' : orientation === 'vertical', 'component-container-landing' : action === 'landing', 'component-container' : action != 'landing' }">
  <div class="row" [ngClass]="{ 'hiddable': action != 'landing' }">
    <div class="col s12 site-welcome"
      [ngClass]="{ 'small-welcome' : (orientation === 'vertical' && action != 'landing') }"
      [innerHtml]="'texts.site-welcome' | translate"></div>
  </div>
  <div class="row includes" [ngClass]="{ 'hiddable': action != 'landing' }">
    <div class="col s5 includes-item item-left">
      <fa *ngIf="action == 'landing'" name="hotel" size="3x" class="icon-include"></fa>
      <fa *ngIf="action != 'landing'" name="hotel" size="lg" class="icon-include"></fa>
      <div>Hotel</div>
      <div class="small-text">
        *{{ ((orientation === 'vertical' && action != 'landing') ? 'texts.included-in-price' : 'texts.included-in-packages') | translate }}
      </div>
    </div>
    <div class="col s5 includes-item item-right">
      <fa *ngIf="action == 'landing'" name="bus" size="3x" class="icon-include"></fa>
      <fa *ngIf="action != 'landing'" name="bus" size="lg" class="icon-include"></fa>
      <div>Tours</div>
      <div class="small-text">
        *{{ ((orientation === 'vertical' && action != 'landing') ? 'texts.included-in-price' : 'texts.included-in-packages') | translate }}
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="{ 'hiddable': action != 'landing', 'shown': isChanging }">
    <div *ngIf="!isTinyMobile" class="col s12 explanatory-text" [ngClass]="{ 'explanatory-text-medium': action != 'landing' }"
      innerHTML="{{ (action === 'landing' ? 'texts.search-msg' : 'texts.search-short-msg') | translate }}">
    </div>
    <div *ngIf="isTinyMobile && action == 'landing'" class="col s12 explanatory-text-mobile" [ngClass]="{ 'explanatory-text-medium': action != 'landing' }"
      innerHTML="{{ 'texts.search-msg-mobile' | translate }}">
    </div>
  </div>
  <form novalidate [formGroup]="searchForm" (ngSubmit)="redirectToSearch()">
    <div class="row search-box-line">
      <div class="col s12"
        [ngClass]="{ 'm8 l7': orientation === 'horizontal', 'hiddable': action != 'landing', 'shown': isChanging }">
        <namu-date-range-input appearance="outline" minRangeLengthAllowed="4" [blockPastDates]="true"
          [invalidDateRangeMsg]="'validations.arrival-less-than-departure' | translate"
          [formGroup]="searchForm.get('dateRange')" [startLabel]="'placeholders.arrival' | translate"
          [endLabel]="'placeholders.departure' | translate" [fullWidthInputs]="orientation === 'vertical'">
        </namu-date-range-input>
      </div>
      <div class="col s6"
        [ngClass]="{ 'm2 l1': orientation === 'horizontal', 'hiddable': action != 'landing', 'shown': isChanging }">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'placeholders.adults' | translate }}</mat-label>
          <input matInput namuOnlyNumber [allowDecimals]="false" formControlName="adults" type="number">
        </mat-form-field>
      </div>
      <div class="col s6"
        [ngClass]="{ 'm2 l1': orientation === 'horizontal', 'hiddable': action != 'landing', 'shown': isChanging }">
        <mat-form-field appearance="outline" class="field-with-footnote">
          <mat-label>{{ 'placeholders.rooms' | translate }}</mat-label>
          <input namuOnlyNumber [allowDecimals]="false" matInput formControlName="rooms" type="number">
        </mat-form-field>
        <mat-hint align="start" class="foot-note">
            <strong>*{{'labels.double-occupancy-only' | translate}}</strong>
        </mat-hint>
      </div>
      <div class="col s12"
        [ngClass]="{ 'l3': orientation === 'horizontal', 'hiddable': action != 'landing', 'shown': isChanging }">
        <button mat-raised-button color="accent" class="search-btn blue darken-3" [disabled]="searchForm.invalid"
          (click)="toggleChangeSearch()">
          {{ (action == 'landing' ? 'labels.find-package' : 'labels.search-again') | translate | uppercase }}
        </button>
      </div>
    </div>
  </form>
  <div *ngIf="action != 'landing'" class="row change-search">
    <div class="col s6">
      <div class="row includes" [ngClass]="{ 'hiddable': isChanging, 'shown': !isChanging }">
        <div class="col s5 includes-item item-left">
          <fa name="hotel" size="sm"></fa>
          <span> Hotel</span>
          <div class="small-text">*{{'texts.included-in-price' | translate }}</div>
        </div>
        <div class="col s5 includes-item item-right">
          <fa name="bus" size="sm"></fa>
          <span> Tours</span>
          <div class="small-text">*{{'texts.included-in-price' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col s6 change-search-btn"
      [ngClass]="{ 'l3': orientation === 'horizontal', 'hiddable': isChanging, 'shown': !isChanging }">
      <button mat-button (click)="toggleChangeSearch()">
        <u>{{ (action == 'landing' ? 'labels.find-package' : 'labels.change-search') | translate | uppercase }}</u>
        <fa class="edit-icon" name="pencil" size="sm"></fa>
      </button>
    </div>
  </div>
</div>