<div class="white-background row" *ngIf="package">
    <div class="col m12 center">
        <h4 class="page-title"><strong>{{'titles.booking-summary' | translate }}</strong></h4>
    </div>
    <div class="col m12">
        <h5 class="center"><strong>{{package.name}}</strong></h5>
        <p class="center" *ngFor="let product of package.products">
            {{'labels.dates' | translate }} {{product.startDate | date: 'MMM dd, yyyy'}} {{ 'labels.to' | translate }}
            {{product.endDate | date: 'MMM dd, yyyy'}}
        </p>
        <p class="center">{{ package.adultsCount }} {{'labels.adults' | translate }}</p>
    </div>
    <div class="row">
        <div class="col m11 offset-l1">
            <div class="col s12 m12 l6 offset-s0 offset-m1 offset-l3">
                <div *ngFor="let product of package.products">
                    <h5>
                        {{ product.hotelName }}
                    </h5>
                    <p>
                        <fa class="icon-location" name="map-marker" size="sm"></fa> {{product.locationName}}
                    </p>
                    <img [src]="product.imagePath" class="product-image">
                    <p *ngFor="let room of product.roomTypes; let i = index">
                        <button mat-button class="room-type" [matTooltip]="'texts.see-room-details' | translate"
                            matTooltipPosition="after" (click)="openRoomDetails(room)">{{room.numberOfRooms}} x
                            {{room.roomTypeName}} {{ 'labels.rooms-x' | translate }} {{room.adultsCount}}
                            {{'labels.adults' | translate}}<mat-icon class="room-type-icon">open_in_new</mat-icon></button>
                    </p>
                    <div class="dinamic-text" [innerHTML]="product.ratePlans[0].htmlDescription"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col m12">
        <h5 class="center"><strong>Activities</strong></h5>
        <div class="row">
            <div class="col s12 m5 offset-s1 offset-m4 offset-l5 ">
                <ul *ngFor="let tour of package.tours">
                    <li>- {{tour.name}} {{'labels.on' | translate }} {{tour.date | date: 'MMM dd, yyyy'}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col m6 l4 offset-l2">
        <div class="row">
            <div class="col m11 offset-m6 center">
                <h5 class="price">${{package.totalRackPrice | number:'1.0-0' }}<span class="amount-discount"> {{'labels.you-save' | translate}}
                        ${{package.discountAmount | number:'1.0-0' }} ({{'labels.off' | translate }}
                        {{((package.discountAmount*100)/package.totalRackPrice) | number:'1.2-2'  }}%)</span></h5>
                <p class="airport-transportation-note">{{'texts.airport-transportation-note' | translate }}</p>
            </div>
        </div>
    </div>
</div>