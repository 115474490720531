import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'namu-terms-and-conditions',
    templateUrl: 'terms-and-conditions-dialog.component.html',
    styleUrls: ['terms-and-conditions-dialog.component.scss']
})

export class TermsAndConditionsDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TermsAndConditionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) { }

    closeDialog(action) {
        this.dialogRef.close({ action: action, data: this.data });
    }
}
