import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'namu-search-bar',
    templateUrl: 'search-bar.component.html',
    styleUrls: ['search-bar.component.scss']
})

export class SearchBarComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute) { }
    @Input() isSearchVisible = false;

    @Input() action: string;

    searchData$ = this.activatedRoute.queryParams;

    ngOnInit() { }
}