import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'namu-error-animation',
    template: `
	<div class="f-modal-icon f-modal-error animate">
		<span class="f-modal-x-mark">
			<span class="f-modal-line f-modal-left animateXLeft"></span>
			<span class="f-modal-line f-modal-right animateXRight"></span>
		</span>
		<div class="f-modal-placeholder"></div>
		<div class="f-modal-fix"></div>
    </div>`,
    styleUrls:['animated-icons.scss']
})

export class ErrorAnimationComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}