import { DATE_FORMAT } from './../../app.settings';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, map, mergeMap, tap, } from 'rxjs/operators';
import { PackageContract } from '../../services';
import { Package, SearchData } from '../../models';
import moment from 'moment-es6';
import { Subscription, of } from 'rxjs';
import { ComponentUtilities } from '../../utilities/component-utilities';
import { Gtag } from 'angular-gtag';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'namu-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})

export class SearchViewComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private packageService: PackageContract) {
  }

  public packages: Package[];
  public isLoading: boolean = false;
  private util = new ComponentUtilities();
  showInvalidTemplateId = false;
  @ViewChild('invalidTemplateId') invalidTemplateIdMsg: TemplateRef<any>;
  showIsSoldOut = false;
  @ViewChild('soldOutMessage') isSoldOutMsg: TemplateRef<any>;
  private orderByTemplateId: number = 0;

  searchData$ = this.activatedRoute.queryParams;
  packagesSubscription = this.searchData$.pipe(
    tap(x => this.isLoading = true),
    mergeMap(({ start, end, adults, rooms, invalidTemplate, isSoldOut, templateId }) => {
      var currentDate = moment();

      start = moment(start, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);
      end = moment(end, ['MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM', 'DD-MM-YYYY']);

      start = start.format(DATE_FORMAT.server);
      end = end.format(DATE_FORMAT.server);

      if ((!start && !end) || moment(start, DATE_FORMAT.server) < currentDate) {
        var searchDefaultData = this.util.getDefaultSearchCriteria();
        start = searchDefaultData.start;
        end = searchDefaultData.end;
        adults = searchDefaultData.adults;
        rooms = searchDefaultData.rooms;
      }

      this.showInvalidTemplateId = invalidTemplate;
      this.showIsSoldOut = isSoldOut ? true: false;
      if(templateId) this.orderByTemplateId = templateId;

      return of({ start, end, adults, rooms });
    }),
    mergeMap(({ start, end, adults, rooms }) => {
      return this.packageService.searchPackages(
        moment(start, DATE_FORMAT.server),
        moment(end, DATE_FORMAT.server), +rooms, +adults, this.orderByTemplateId)
    })
  ).subscribe(
    (packages) => {
      this.packages = packages;
      this.isLoading = false;
    }
  );

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.showInvalidTemplateId)
      this.dialog.open(this.invalidTemplateIdMsg, { disableClose: false })
    if (this.showIsSoldOut)
      this.dialog.open(this.isSoldOutMsg, { disableClose: false })
  }

  ngOnDestroy(): void {
    if (this.packagesSubscription) this.packagesSubscription.unsubscribe();
  }
}