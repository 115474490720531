<div class="landscape-image" *ngIf="invoice">
    <div class="text-container">
        <div class="row">
            <div class="col s12 m8 offset-m2 center header-text">
                <h4 class="responsive-header-text">{{'texts.thank-you-for-reserve' | translate}}</h4>
                <h4 class="responsive-header-text">{{'texts.brand-name' | translate}}</h4>
            </div>
        </div>
        <div class="row reservation-confirmed">
            <div class="s12 center">
                    <h6>{{'texts.reservations-confirmed' | translate }}:</h6>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <div class="left-align confirmation-details">
                    <br>
                    <h6><b>{{invoice.travelers[0].name}}
                            {{invoice.travelers[0].lastName}}</b></h6>
                    <p class="remove-margin-bottom remove-margin-top">{{'texts.confirmation-number' | translate}}: {{invoice.id}}</p>
                    <p class="remove-margin-bottom remove-margin-top">{{'texts.arrival' | translate}}: {{invoice.arrivalDate | date: 'MMM dd, yyyy'}}</p>
                    <p class="remove-margin-bottom remove-margin-top">{{'texts.departure' | translate}}: {{invoice.departureDate | date: 'MMM dd, yyyy'}}</p>
                    <p class="remove-margin-bottom remove-margin-top">{{'texts.number-of-guest' | translate}}: {{invoice.adultsCount}}</p>
                </div>
            </div>
        </div>
        <div class="row hide-on-small-only hide-on-med-only">
            <div class="col m5 offset-m4">
                <div class="row">
                    <div class="col m6 s12 left-align" *ngFor="let product of invoice.products;">
                        <h6><b>{{product.hotelName}}</b></h6>
                        <div *ngFor="let room of product.roomTypes; let i = index">
                            <p class="remove-margin-bottom remove-margin-top">{{room.numberOfRooms}} x {{room.roomTypeName}} room(s)
                            </p>
                        </div>
                        <div *ngFor="let room of product.roomTypes; let i = index">
                            <p class="remove-margin-bottom remove-margin-top" *ngIf="i == 0">
                                {{product.ratePlans[0].ratePlanName}}
                            </p>
                            <p class="remove-margin-bottom remove-margin-top" *ngIf="i == 0">
                                {{'labels.check-in' | translate}}: {{product.checkInTime | date: 'h:mm a'}} /
                                {{'labels.check-out' | translate}}: {{product.checkOutTime | date: 'h:mm a'}}
                            </p>
                        </div>
                    </div>
                    <div class="col m1"></div>
                    <div class="col m4 s12 left-align">
                        <h6><b>{{'titles.your-activities' | translate}}</b></h6>
                        <div *ngFor="let tour of invoice.tours; let rowIndex=index">
                            <p class="remove-margin-bottom remove-margin-top">{{tour.name}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col s12 m8 offset-m2 center contact-text">
                <h6>{{'texts.all-travel-dates-email' | translate}}</h6>
                <br>
                <h6 class="responsive-contact-text">{{'texts.questions-concerns' | translate}}
                    {{'texts.reservations-department-at' | translate}}</h6>
                <h6 class="responsive-contact-text">{{'texts.reservations-contact-info' | translate}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col s12 m8 offset-m2 center search-box-text">
                <h5 class="responsive-sbox-text">{{'texts.looking-forward' | translate}}</h5>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!crvRoute"> 
    <namu-footer></namu-footer>
</div> 