<div class="row btn-container">
    <button mat-raised-button class="price-btn blue-grey darken-2"
        [matTooltip]="('texts.price-explanation1' | translate)+((_package.tours||[]).length)+('texts.price-explanation2' | translate)+((_package.tours||[]).length > 0 ? _package.tours[0].adultsCount : 0)+('texts.price-explanation3' | translate)"
        matTooltipPosition="left" *ngIf="!_package.isSoldOut"><span class="pkg-price">
            ${{_package.totalRackPrice | number:'1.0-0' }}</span>
        <div class="narrow-line total-price-lbl"><u>{{ 'labels.total-pkg-price-low' | translate }}</u></div>
    </button>
</div>
<div class="row name-badge" *ngIf="!_package.isSoldOut">
    <div class="col s12 m7 l7 pkg-title blue-grey darken-2">
        <p class="pkg-name show-on-large hide-on-med-and-down">{{_package.name}}</p>
        <p class="pkg-name show-on-medium-and-down hide-on-large-only">{{_package.name}}</p>
    </div>
</div>
<div class="row price-badge" *ngIf="!_package.isSoldOut">
    <div class="col s12 pkg-price-title blue-grey darken-2"
        [matTooltip]="('texts.price-explanation1' | translate)+((_package.tours||[]).length)+('texts.price-explanation2' | translate)+((_package.tours||[]).length > 0 ? _package.tours[0].adultsCount : 0)+('texts.price-explanation3' | translate)"
        matTooltipPosition="left">
        <span class="pkg-price">
            ${{_package.totalRackPrice | number:'1.0-0' }}</span>
        <span class="total-price-lbl">
            {{ 'labels.total-pkg-price-low' | translate }}</span>
    </div>
</div>
<div class="row name-badge" *ngIf="_package.isSoldOut">
    <div class="col s12 m6 red darken-1">
        <p class="pkg-name">{{ 'labels.sold-out' | translate }}<span class="sold-out-msg">
                {{ 'texts.sold-out-msg' | translate }}</span></p>
    </div>
</div>
<mat-card class="card">
    <mat-card-content [ngClass]="{ 'sold-out-background': _package.isSoldOut }">
        <div class="row product-container">
            <div class="col s12 xl9">
                <namu-hotel-list [packageDescription]="_package.description" [products]="_package.products"
                    [pax]="((_package.tours||[]).length > 0 ? _package.tours[0].adultsCount : 0)"
                    [packageId]="_package.id" [isSoldOut]="_package.isSoldOut">
                </namu-hotel-list>
            </div>
            <div class="col s12 read-more"
                *ngIf="((_package.tours||[]).length > 0 ? _package.tours[0].adultsCount : 0) > 0">
                <button mat-button class="see-more-btn" (click)="toggleReadMore()">
                    <fa class="tour-info-icon" name="bus" size="sm"></fa><u><span
                        class="qtys">{{ (_package.tours||[]).length }}</span> {{ 'labels.see-tours' | translate }}<span
                        class="qtys">{{ _package.tours[0].adultsCount }}</span>{{ 'labels.see-tours2' | translate }}</u>
                    <mat-icon class="tour-more-icon">{{ !isReadingMore() ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
                    </mat-icon>
                </button>
            </div>
            <div class="col s12 xl3" *ngIf="isReadingMore()">
                <namu-tour-list [tours]="_package.tours" *ngIf="(_package.tours||[]).length"></namu-tour-list>
            </div>
        </div>
        <div class="row book-now" *ngIf="!_package.isSoldOut">
            <div class="col s12 book-now-col">
                <button mat-raised-button class="card-btn blue darken-3"
                    (click)="redirectToBooking(_package.id)">{{ 'labels.book-now' | translate }}
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>