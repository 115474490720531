import { Product } from '.';
import { Tour } from '.';

export class Package {
    id: number;
    name: string;
    description: string;
    products: Product[];
    isSoldOut: boolean;
    totalRackPrice: number;
    tours: Tour[]
    templateId: number;
    discountAmount: number;
    adultsCount:number;
    constructor() { }
}
